import React from 'react'
import { DashboardProvider } from 'contexts/DashboardContext'
import { SearchBarProvider } from 'contexts/SearchBarContext'

export default function AllProjectsWrapper({ children }) {
  return (
    <DashboardProvider>
      <SearchBarProvider>
        {children}
      </SearchBarProvider>
    </DashboardProvider>
  )
}