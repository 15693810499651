let pos = { top: 0, left: 0, x: 0, y: 0 };
let mouseUpHandlerWrapper = undefined;
let el = undefined;  // Needs global scope in case mouseup happens outside of desired target

export default function scrollDrag(e, setScrollSnap, scrollSnapDispatch) {  
  if(e.target.closest('.card')) return;  // Do nothing because user is likely dragging a draggable element

  el = e.target.closest('[data-rbd-droppable-id]');
  
  pos = {
    // The current scroll
    left: el.scrollLeft,
    top: el.scrollTop,
    // The current mouse position
    x: e.clientX,
    y: e.clientY,
  }

  mouseUpHandlerWrapper = () => mouseUpHandler(setScrollSnap, scrollSnapDispatch);

  // REMOVE SCROLL SNAP
  setScrollSnap(scrollSnapDispatch, { scrollSnap: false });

  // BIND EVENT LISTENERS
  document.addEventListener('mousemove', mouseMoveHandler);
  document.addEventListener('mouseup', mouseUpHandlerWrapper);
}

const mouseMoveHandler = e => {
  // How far the mouse has moved
  const dx = e.clientX - pos.x;
  const dy = e.clientY - pos.y;

  // SCROLL THE ELEMENT
  el.scrollTop = pos.top - dy;
  el.scrollLeft = pos.left - dx;
}

const mouseUpHandler = (setScrollSnap, scrollSnapDispatch) => {
  // UNBIND EVENT LISTENERS
  document.removeEventListener('mousemove', mouseMoveHandler);
  document.removeEventListener('mouseup', mouseUpHandlerWrapper);

  // SMOOTH SCROLL FINISH
  const duration = 200;
  const scrLft = el.scrollLeft;
  const colWidth = el.querySelector('div').offsetWidth;
  const threshold = (scrLft / colWidth) % 1;
  const dir = threshold > 0.5 ? 1 : -1;

  Array.from(Array(100).keys()).map(i => (
    setTimeout(() => {
      el.scrollLeft = scrLft + (dir * ((threshold > 0.5 ? 1 - threshold : threshold) * colWidth) * i / 100)
    }, i / 100 * duration)
  ));

  // RESTORE SCROLL SNAP
  setTimeout(() => setScrollSnap(scrollSnapDispatch, { scrollSnap: true }), duration);
}