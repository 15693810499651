import { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { stateUpdateDashboard, useDashboardDispatch, useDashboardState } from 'contexts/DashboardContext'
import Main from 'layouts/Main'
import useHook01 from './useHook01'
import event01 from './event01'

export default function DashboardContextSandbox() {
  // CONTEXT PARAMS - DASHBOARD
  const { buckets, bucketsRef } = useDashboardState();
  const dashboardDispatch = useDashboardDispatch();

  useEffect(() => {
    console.log({ buckets, bucketsRef });
    stateUpdateDashboard(dashboardDispatch, { buckets: [ Math.floor(Math.random() * 100) ] });
  }, []);

  useEffect(() => { bucketsRef.current = buckets }, [buckets]);

  useHook01(() => event01({ 
    callback: () => event01({ callback: () => console.log({ buckets }), dataset: bucketsRef.current }), 
    dataset: bucketsRef.current, 
  }), 3000);

  return (
    <Main title="DashboardContextSandbox" subtitle="Sandbox" icon="wrench">
      <p>{JSON.stringify(buckets)}</p>
      
      <Button onClick={() => stateUpdateDashboard(dashboardDispatch, { buckets: [...buckets, Math.floor(Math.random() * 100) ] })}>
        Append Number
      </Button>
    </Main>
  )
}