import { useEffect } from 'react'

export default function useIntersectionObserver(ref, callback) {  // https://phuoc.ng/collection/intersection-observer-with-react/use-the-intersection-observer-api-in-react/
  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const observer = new IntersectionObserver(([entry]) => callback(entry));
    observer.observe(element);

    return () => observer.unobserve(element);
  }, [ref.current]);
}