import React, { useRef } from 'react';

const SearchBarStateContext = React.createContext();
const SearchBarDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useSearchBarState() {
  const context = React.useContext(SearchBarStateContext);
  if(!context) throw new Error('useSearchBarState must be used in SearchBarProvider');
  return context;
}

function useSearchBarDispatch() {
  const context = React.useContext(SearchBarDispatchContext);
  if(!context) throw new Error('useSearchBarDispatch must be used in SearchBarProvider');
  return context;
}

function SearchBarProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, {
    dataset: [], 
    datasetRef: useRef(null), 
    filtered: false, // TODO: remove?
    filterCallback: undefined, 
    filteredDataset: [], 
    searchQuery: '', 
    stateKey: '', 
    stateType: undefined, 
    stateUpdate: undefined, 
  });

  return (
    <SearchBarStateContext.Provider value={state}>
      <SearchBarDispatchContext.Provider value={dispatch}>
        {props.children}
      </SearchBarDispatchContext.Provider>
    </SearchBarStateContext.Provider>
  )
}

function stateFilterDataset(dispatch, state) {
  const filteredDataset = state.filterCallback(state.dataset, state.searchQuery);

  state.stateUpdate(state.dispatch, { 
    [state.stateKey]: state.stateType === Array ? filteredDataset : filteredDataset.at(0)
  });  // Don't use Array.shift()

  dispatch({ filteredDataset });
}

function stateUpdateSearchQuery(dispatch, { searchQuery }) {
  dispatch({ filtered: searchQuery !== '', searchQuery });
}

function stateUpdateSearchBar(dispatch, data) {
  dispatch({...data});
}

function stateUpdateDataset(dispatch, { dataset }) {
  dispatch({ dataset });
}

export { 
  SearchBarProvider, 
  stateFilterDataset, 
  stateUpdateDataset, 
  stateUpdateSearchBar, 
  stateUpdateSearchQuery, 
  useSearchBarDispatch, 
  useSearchBarState, 
}