export default class ApiEvents {
  // CUSTOMER EVENTS
  static CUSTOMER_BALANCE_CREDITED = 'App\\Events\\CustomerBalanceCredited'; 
  static CUSTOMER_BALANCE_DEBITED = 'App\\Events\\CustomerBalanceDebited'; 
  
  static FRONTEND_VERSION_UPDATED = 'App\\Events\\FrontendVersionUpdated';

  // TASK EVENTS
  static TASK_UPDATED = 'App\\Events\\TaskUpdated';

  // TENANT EVENTS
  static TENANT_UPDATED = 'App\\Events\\TenantUpdated';
  
  // USER EVENTS
  static USER_CREATED = 'App\\Events\\UserCreated';
  static USER_DELETED = 'App\\Events\\UserDeleted';
  static USER_UPDATED = 'App\\Events\\UserUpdated';
}