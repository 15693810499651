import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faRectangleXmark, faSearch } from '@fortawesome/pro-regular-svg-icons';
// import { faRectangleXmark, faSearch } from "assets/fontawesome-6/svgs/regular";
import useDebounce from 'hooks/useDebounce';
import useStateCallback from 'hooks/useStateCallback';
import { stateUpdateSearchBar, useSearchBarDispatch, useSearchBarState } from 'contexts/SearchBarContext'

export default function SearchBar({ 
  dataset,
  disabled,
  dispatch,
  filterCallback,
  stateUpdate,
  stateKey,
  stateType,
}) {
  // CONTEXT - SEARCH BAR
  const searchBarDispatch = useSearchBarDispatch();

  // STATE PARAMS
  const [searchQuery, setSearchQuery] = useStateCallback('');

  useDebounce(() => {
    const filteredDataset = filterCallback(dataset, searchQuery);
    stateUpdate(dispatch, { [stateKey]: stateType === Array ? filteredDataset : filteredDataset[0] });  // Don't use Array.shift()
    stateUpdateSearchBar(searchBarDispatch, { filtered: searchQuery !== '' });
  }, 100, [searchQuery]);  // Short debounce timeout because search is client-side

  const handleOnChange = e => setSearchQuery(e.target.value);

  return (
    <Form 
      autoComplete="off" 
      noValidate 
      onSubmit={e => e.preventDefault()}
    >
      <InputGroup className="shadow-sm rounded mb-2">
        <Form.Control 
          className="transparentBorder" 
          disabled={disabled}
          name="search" 
          onChange={handleOnChange} 
          placeholder="Search..." 
          type="text" 
          value={searchQuery} 
        />
        <Button 
          onClick={() => setSearchQuery('', stateUpdateSearchBar(searchBarDispatch, { filtered: false }))}
          variant={searchQuery.length > 0 ? 'warning' : 'primary'} 
        >
          <FontAwesomeIcon 
            color="white" 
            fixedWidth 
            icon={['far', searchQuery.length > 0 ? 'rectangle-xmark' : 'magnifying-glass']} 
          />
        </Button>
      </InputGroup>
    </Form>
  )
}