import { useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import { stateUpdateFormControl, useFormControlDispatch, useFormControlState } from 'contexts/FormControlContext'
import { useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import useStateCallback from 'hooks/useStateCallback'

export default function FormControl({ 
  as, 
  autoFocus,
  className, 
  modelContext, 
  disabledProp, 
  name, 
  onBlur, 
  onFocus, 
  onKeyDown, 
  placeholder, 
  _ref, 
  readOnly, 
  required, 
  type, 
  value, 
}) {
  // CONTEXT PARAMS - FORM CONTROL
  const { disabled } = useFormControlState();
  const formControlDispatch = useFormControlDispatch();

  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  const [val, setVal] = useStateCallback(value);
  const ref = useRef(null);

  useEffect(() => setVal(value), [value]);

  return (
    <Form.Control
      as={as ?? undefined}
      autoFocus={autoFocus ?? false}
      className={className}
      disabled={disabled || disabledProp}
      name={name}
      onBlur={e => onBlur(e, ref, value, setVal, modelContext, { dispatch: formControlDispatch, update: stateUpdateFormControl }, { toasts, toastsDispatch })}
      onChange={e => setVal(e.target.value)}
      onFocus={onFocus}
      onKeyDown={e => onKeyDown(e, ref, value, setVal, modelContext, { dispatch: formControlDispatch, update: stateUpdateFormControl }, { toasts, toastsDispatch })}
      placeholder={placeholder}
      readOnly={readOnly}
      ref={_ref ?? ref}
      required={required}
      type={type ?? "text"}
      value={val}
    />
  )
}