// DEPRECATED

export default class DragAndDrop {
  static BUCKETS = 'buckets';
  static TASKS = 'tasks';

  constructor(buckets) {
    this.buckets = buckets;
  }

  getBucketIndex(droppableId) {
    return this.buckets.findIndex(bucket => String(bucket.id) === String(droppableId).match(/\d+/g)[0]);
  }

  getTaskIndex(draggableId, sourceDroppableId) {
    const sourceBucketIndex = this.getBucketIndex(sourceDroppableId);
    return this.buckets[sourceBucketIndex].tasks.findIndex(task => String(task.id) === String(draggableId).match(/\d+/g)[0]);
  }

  moveTask(destination, source) {
    const sourceBucketIndex = this.getBucketIndex(source.droppableId);
    const destinationBucketIndex = this.getBucketIndex(destination.droppableId);
    
    // REMOVE TASK FROM SOURCE BUCKET
    const task = this.buckets[sourceBucketIndex].tasks.splice(source.index, 1)[0];

    // ADD TASK TO DESTINATION BUCKET
    this.buckets[destinationBucketIndex].tasks.splice(destination.index, 0, task);
  }

  moveBucket(destination, source) {
    const bucket = this.buckets.splice(source.index, 1)[0];
    this.buckets.splice(destination.index, 0, bucket);
  }
}