import { Button, Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { stateFilterDataset, stateUpdateSearchBar, stateUpdateSearchQuery, useSearchBarDispatch, useSearchBarState } from 'contexts/SearchBarContext'
import useDebounce from 'hooks/useDebounce'
import useStateCallback from 'hooks/useStateCallback'
import { useEffect } from 'react'

class Constants {
  static DEBOUNCE_TIMEOUT = 100;  // Short debounce timeout because search is client-side
}

export default function SearchBar({ 
  dataset,
  disabled,
  dispatch,
  filterCallback,
  stateKey,
  stateType,
  stateUpdate,
}) {
  // CONTEXT - SEARCH BAR
  const searchBarDispatch = useSearchBarDispatch();
  const searchBarState = useSearchBarState();

  // STATE PARAMS
  const [searchQuery, setSearchQuery] = useStateCallback('');

  useEffect(() => {
    // Initialise Search Bar state
    stateUpdateSearchBar(searchBarDispatch, { 
      dataset,
      dispatch,
      filterCallback,
      stateKey,
      stateType,
      stateUpdate,
    });
  }, []);

  useDebounce(() => {
    stateUpdateSearchQuery(searchBarDispatch, { searchQuery });
    stateFilterDataset(searchBarDispatch, {...searchBarState, searchQuery});
  }, Constants.DEBOUNCE_TIMEOUT, [searchQuery]);

  const handleOnChange = e => setSearchQuery(e.target.value);

  return (
    <Form 
      autoComplete="off" 
      noValidate 
      onSubmit={e => e.preventDefault()}
    >
      <InputGroup className="shadow-sm rounded mb-2">
        <Form.Control 
          className="transparentBorder" 
          disabled={disabled}
          name="search" 
          onChange={handleOnChange} 
          placeholder="Search..." 
          type="text" 
          value={searchQuery} 
        />
        <Button 
          onClick={() => setSearchQuery('', stateUpdateSearchBar(searchBarDispatch, { filtered: false }))}
          variant={searchQuery.length > 0 ? 'warning' : 'primary'} 
        >
          <FontAwesomeIcon 
            color="white" 
            fixedWidth 
            icon={['far', searchQuery.length > 0 ? 'rectangle-xmark' : 'magnifying-glass']} 
          />
        </Button>
      </InputGroup>
    </Form>
  )
}