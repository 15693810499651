export default class DragDrop {
  static BUCKETS = 'buckets';
  static TASKS = 'tasks';

  constructor(buckets) {
    this.buckets = buckets;
  }

  moveBucket(destination, source) {
    const bucket = this.buckets.splice(source.index, 1).at(0);
    this.buckets.splice(destination.index, 0, bucket);
  }

  static onDragEnd = ({ destination, source, type, draggableId }, { buckets, dataset }) => {
    if(!destination) return;

    if(
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) return;

    const dndBuckets = new DragDrop(structuredClone(buckets));
    const dndDataset = new DragDrop(structuredClone(dataset));

    switch(type) {
      case DragDrop.BUCKETS:
        dndBuckets.moveBucket(destination, source);
        // dndDataset.moveBucket(destination, source);
        // TODO: API call to update order
        break;
      case DragDrop.TASKS:
        // dnd.moveTask(destination, source);
        // dndDataset.moveTask(destination, {
        //   index: dndDataset.getTaskIndex(draggableId, source.droppableId),
        //   droppableId: source.droppableId
        // });
        // TODO: API call to update order
        break;
      default: break;
    }

    return { newBuckets: dndBuckets.buckets, newDataset: dndDataset.buckets };
  }

}