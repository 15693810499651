export default class ApiRoutes {
  static ACCOUNT = 'api/v1/account';
  static ACCOUNT_EMAIL_VERIFY = 'api/v1/account/email/verification/fulfill';
  static ACCOUNT_LOGIN = 'api/v1/account/login';
  static ACCOUNT_LOGOUT = 'api/v1/account/logout';
  static ACCOUNT_PASSWORD_FORGOT = 'api/v1/account/password/forgot';
  static ACCOUNT_PASSWORD_RESET = 'api/v1/account/password/reset';

  static BROADCASTING_CHANNEL_AUTH = 'api/v1/broadcasting/channel-auth';
  
  static BUCKETS = 'api/v1/buckets';
  static BUCKET = (bucketId) => `api/v1/buckets/${bucketId}`;
  static BUCKETS_TASKS = (bucketId) => `api/v1/buckets/${bucketId}/tasks`;
  static BUCKETS_TASKS_PAGINATION = (bucketId, page) => `api/v1/buckets/${bucketId}/tasks?page=${page}`;
  
  static FRONTEND_VERSION = 'api/v1/frontend/version';
  
  static CASHIER_CUSTOMER_BALANCE = 'api/v1/cashier/customer/balance';
  static CASHIER_CUSTOMER_BALANCE_TRANSACTION = 'api/v1/cashier/customer/balance-transaction';
  static CASHIER_PAYMENT_INTENT = 'api/v1/cashier/payment-intent';
  
  static SMS = 'api/v1/sms';
  
  static TASK = (taskId) => `api/v1/tasks/${taskId}`;
  
  static TASK_CHECKLISTS = (taskId) => `api/v1/tasks/${taskId}/checklists`;
  static TASK_CHECKLIST = (checklistId) => `api/v1/tasks/checklists/${checklistId}`;
  
  static TASK_CHECKLIST_ITEMS = (checklistId) => `api/v1/tasks/checklists/${checklistId}/items`;
  static TASK_CHECKLIST_ITEM = (checklistItemId) => `api/v1/tasks/checklists/items/${checklistItemId}`;
  
  static TASK_CLIENT = (taskId) => `api/v1/tasks/${taskId}/client`;
  
  static TASK_COMMENTS = (taskId) => `api/v1/tasks/${taskId}/comments`;
  static TASK_COMMENT = (commentId) => `api/v1/tasks/comments/${commentId}`;
  
  static TASK_USER = (taskId, userId) => `api/v1/tasks/${taskId}/users/${userId}`;
  
  static TENANT = 'api/v1/tenant';

  static USERS = 'api/v1/users';
}