import React, { useRef } from 'react'

const DashboardStateContext = React.createContext();
const DashboardDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useDashboardState() {
  const context = React.useContext(DashboardStateContext);
  if(!context) throw new Error('useDashboardState must be used in DashboardProvider');
  return context;
}

function useDashboardDispatch() {
  const context = React.useContext(DashboardDispatchContext);
  if(!context) throw new Error('useDashboardDispatch must be used in DashboardProvider');
  return context;
}

function DashboardProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, { 
    buckets: [],
    bucketsRef: useRef(null),
    scrollSnap: true,
  });

  return (
    <DashboardStateContext.Provider value={state}>
      <DashboardDispatchContext.Provider value={dispatch}>
        {children}
      </DashboardDispatchContext.Provider>
    </DashboardStateContext.Provider>
  )
}

function stateUpdateDashboard(dispatch, data) {
  dispatch({...data});
}

export { 
  DashboardProvider,
  stateUpdateDashboard,
  useDashboardDispatch,
  useDashboardState,
};