// https://github.com/facebook/react/issues/14010#issuecomment-433788147
import Dashboard from 'assets/js/classes/Dashboard'
import handleToast from './handleToast'

// eventTaskUpdated(data, modelContext, toastsContext)
export default function eventTaskUpdated({ author, user, task }, { dashboard, searchBar }, { toasts, toastsDispatch, updateToasts }) {
  console.log({author, user, task, 'dashboard.dataset': dashboard.dataset, 'searchBar.dataset': searchBar.dataset})
  console.log({ eventGetTaskIndex: Dashboard.eventGetTaskIndex(task, dashboard.dataset) })

  if ([...Object.values(Dashboard.eventGetTaskIndex(task, dashboard.dataset))].find(el => el === -1)) return;  // If task isn't loaded via API call yet.
  
  [dashboard, searchBar].forEach(({ dataset, dispatch, prop, update }) => {
    Dashboard.eventTaskUpdated(task, dataset);
    update(dispatch, { [prop]: dataset });
  });
  
  if (author?.id === user?.id) return;  // Don't throw toast.

  handleToast({
    title: 'Task Updated', 
    message: `Task #${task?.id} was updated by ${author?.name} (${author?.email}). The latest version has automatically been loaded for you.`,
    autohide: true, 
  }, { toasts, toastsDispatch, updateToasts });
}