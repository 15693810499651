import { useEffect, useRef, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sentiment } from 'assets/js/classes/BootstrapStyles'
import SB from 'assets/js/classes/SubmitBtn'
import { stateUpdateFormControl, useFormControlDispatch, useFormControlState } from 'contexts/FormControlContext'
import { useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import useStateCallback from 'hooks/useStateCallback'
import { updateSweetAlert, useSweetAlertDispatch } from 'contexts/SweetAlertContext'
import SwalClass from 'assets/js/classes/SweetAlert'
import useRegisterEventListeners from 'hooks/useRegisterEventListeners'

export default function FormButton({ 
  alert,
  children,
  className,
  eventListeners,
  modelContext,
  disabledProp,
  hidden,
  loadStatus,
  onClick,
  variant,
}) {
  // CONTEXT PARAMS - FORM CONTROL
  const { btnState, disabled, response } = useFormControlState();
  const formControlDispatch = useFormControlDispatch();
  
  // CONTEXT PARAMS - SWEET ALERT
  const sweetAlertDispatch = useSweetAlertDispatch();

  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  // STATE PARAMS
  const [buttonState, setButtonState] = useState(SB.DEFAULT);
  const [buttonDisabled, setButtonDisabled] = useState((disabled || disabledProp) ?? false);
  const [buttonVariant, setButtonVariant] = useStateCallback(variant ?? Sentiment.PRIMARY);
  
  const hasText = Object.values(children).find(child => typeof child === 'string') ?? false;
  const ref = useRef(null);

  // REGISTER EVENT LISTENERS
  useRegisterEventListeners(ref, eventListeners);

  // FUNCTIONS
  const toggleButtonStyle = () => {
    Object.values(ref.current.classList.value.match(/btn-outline-\w*/gi) ?? {}).forEach(value => ref.current.classList.toggle(value));
    Object.values(ref.current.classList.value.match(/text-\w*/gi) ?? {}).forEach(value => ref.current.classList.toggle(value));
  }

  // COMPONENT MOUNT
  useEffect(() => {
    switch(btnState) {
      case SB.LOADING:
        setButtonState(btnState);
        setButtonDisabled(true);
        setButtonVariant(variant ?? Sentiment.PRIMARY, toggleButtonStyle);
      break;

      case SB.RESPONSE:
        setButtonState(btnState);
        setButtonDisabled(true);
        setButtonVariant(response?.status >= 200 && response?.status < 300 ? Sentiment.SUCCESS : Sentiment.DANGER, toggleButtonStyle);
      break;
      
      default:
        setButtonState(SB.DEFAULT);
        setButtonDisabled((disabled || disabledProp) ?? false);
        setButtonVariant(variant ?? Sentiment.PRIMARY);
    }
  }, [btnState, disabledProp, response]);

  const handleOnClick = e => {
    if (alert && Object.values(alert).length > 0) {
      updateSweetAlert(sweetAlertDispatch, { 
        alert: new SwalClass(
          alert.title,
          alert.message,
          {
            ...alert.options,
            onConfirm: () => onClick(
              e, 
              modelContext, 
              { dispatch: formControlDispatch, update: stateUpdateFormControl }, 
              { toasts, toastsDispatch }
            ),
          }
        )
      });
    } else {
      onClick(e, modelContext, { dispatch: formControlDispatch, update: stateUpdateFormControl }, { toasts, toastsDispatch });
    }
  }

  return (
    <Button
      className={className}
      disabled={buttonDisabled}
      hidden={hidden}
      onClick={handleOnClick}
      ref={ref}
      variant={buttonVariant}
    >
      {buttonState === SB.DEFAULT && children}
      {buttonState === SB.LOADING && 
        <><Spinner animation="border" size="sm" className={hasText ? 'mr-1' : ''} />{hasText ? loadStatus ?? 'Saving ...' : ''}</>
      }
      {buttonState === SB.RESPONSE && 
        <>
          <FontAwesomeIcon 
            className={hasText ? 'mr-1' : ''}
            fixedWidth 
            icon={['fas', response?.status >= 200 && response?.status < 300 ? 'circle-check' : 'circle-xmark']} 
          />{hasText ? response?.status >= 200 && response?.status < 300 ? 'Success!' : 'Failed!' : ''}
        </>
      }
    </Button>
  )
}